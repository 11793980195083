.store {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 75%;
}
a {
    display: inline-block;
    padding-left: 5%;
    padding-right: 5%;
}
#firstParagraph {
    text-align: center;
    line-height: 2;
}