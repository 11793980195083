.banner {
    display: block;
    padding-top: 1%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 75%;
}
img.banner__splash {
    border-radius: 20px;
}