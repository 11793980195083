.footer {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
}
#a-footer  {
    display:inline;
    padding: 0%;
}
h1 {
    font-size: 3em;
    font-weight: bold;
    text-align: center;
}
h2 {
    font-size: larger;
    font-weight: bold;
    text-align: center;
}
p {
    text-align: center;
    line-height: 2;
}